@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*
{
    font-family: 'Roboto', sans-serif;
}
/* npx tailwindcss-cli@latest build src/index.css -o public/dist/css/styles.css */
    
/* ::-webkit-scrollbar              { width: 6px; height: 6px;}
::-webkit-scrollbar-button       { background:#727272;}
::-webkit-scrollbar-track        { background:#616161}
::-webkit-scrollbar-track-piece  { background:#333333; }
::-webkit-scrollbar-thumb        { background:#888888; }
::-webkit-scrollbar-corner       { background:#999999; }
::-webkit-resizer     {width: 6px; height: 6px;} */


::-webkit-scrollbar {
    width: 6px; height: 6px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 6px;
}


@layer components {
    /* .btn-blue {
      @apply px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
    } */
    .text-input-ks {
        @apply block w-full px-4 py-3 mx-auto mt-2 text-sm font-normal text-gray-600 placeholder-gray-600 transition duration-150 ease-in-out border border-black rounded-full sm:leading-5 focus:outline-none focus:text-gray-900 focus:border-gray-800 focus:bg-gray-100;
    }
    .text-input-border {
        @apply border border-dark-400 bg-dark-400 ;
    }
    .text-input-border-error {
        @apply block w-full px-4 py-3 text-sm font-normal text-gray-700 placeholder-gray-600 transition duration-150 ease-in-out rounded-full appearance-none focus:outline-none focus:border-gray-800 focus:bg-gray-900 focus:text-gray-600 sm:leading-5;
    }
    
} 

/* {<textarea className={`text-input-ks ${!this.state.errorsticket["mobNo"] ? "border border-dark-400 bg-dark-400" : "border border-yellow-400 bg-dark-400 "}`}} */


input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #48bb78;
  }
 

.temp001{
    /* height: 100%; */
    position: static !important;
}

.pagination { 
    @apply items-center flex
}

 
.pagination > li > a,
.pagination > li > span {
    @apply relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 cursor-pointer bg-black bg-opacity-10
}

.pagination > li:nth-child(2n + 1) > a,
.pagination > li:nth-child(2n + 1) > span{
    @apply bg-opacity-5
}

.pagination>li.active>a {
 @apply bg-[#2196F3] text-white   
 
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
    @apply bg-[#F9FAFB]  text-[#2196F3]
       
    }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
       @apply rounded-l block
       
    }
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
        @apply rounded-r block mr-0
       
    }

    .toggle-checkbox:checked {
        @apply: right-0 border-green-400;
        right: 0;
        border-color: #68D391;
      }
      .toggle-checkbox:checked + .toggle-label {
        @apply: bg-green-400;
        background-color: #68D391;
      }